import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import Home from './pages/home/home'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <>
      <Router>
          <Switch>
            <Route exact={true} path="/impressum">
              <nav className="navbar navbar-expand navbar-light bg-light">
                <div className="container-fluid">
                  <span className="navbar-brand mb-0 h1">Nordpar</span>
                </div>
              </nav>
              <div className='container-fluid mt-3'>
                <h1>Impressum</h1>
                <p>
                  <h4><b>Nordpar ApS</b></h4>
                  <h4>
                    <div>C.F. Møllers Allé 2C</div>
                    <div>2300 Copenhagen Denmark</div>
                    <div>VAT: DK40071555</div>
                  </h4>
                </p>
                <p className='mt-4'>
                  <h4>Telephone number: +45 49 40 41 49</h4>
                  <h4>E-mail: info@norpdar.dk</h4>
                  <h4>Managing Director: Christian Hasselstrøm</h4>
                </p>
              </div>
            </Route>
            <Route path="/:country">
                  <Home />
            </Route>
            <Route path="/">
                  <Home />
            </Route>
          </Switch>
      </Router>
    </>
  )
}

export default App
