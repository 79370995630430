import { useState, useEffect,createElement } from 'react'

import Navbar from './navbar/navbar'

function getShopOrigin(product:any) {
  try {
    const urlStr = product.link
    const params = new URLSearchParams(urlStr)
    const shopUrl = params.get('htmlurl') ||  params.get('r') || params.get('u')
    
    const url = new URL(shopUrl || urlStr)

    return url.hostname
  } catch {
    return ''
  }
}

function getBrand(product:any) {
  return product.brand || ''
}

function openLink(link:string) {
  window.open(link, '_blank')
}

function getPrice(product?:any) {
  return product.price || ''
}

function getCountry() {
  const [country] = window.location.pathname.split('/').reverse()
  return country || 'dk'
}

function isDev(){ 
  return '_self' in createElement('div')
}

function Home() {
      const [products, setProducts] = useState([] as any[])
      const [isLoading, setLoading] = useState(true)
      const [stores, setStores] = useState([] as any[])
      const [country, setCountry] = useState(getCountry())
      const [countries, setCountries] = useState([] as { country: string, flag: string, name:string }[])

      useEffect(()=> {
        const url = isDev() ? `http://localhost:5002/get-countries` : `https://api.shopping.nordpar.com/get-countries`
        fetch(url).then(r => r.json()).then(v => {
          v.sort((a:any, b:any) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          })
          setCountries(v)
        })

      },[setCountries])
      
      
      
      return (
          <>
            <Navbar isLoading={isLoading} products={products} setProducts={setProducts} setStores={setStores} stores={stores} setLoading={setLoading} country={country} countries={countries} setCountry={setCountry} />
            <div className="container-fluid">
              <div className="row mx-2 mt-3">
                {products.map((product, i) => (
                  <div key={i} className="col-md-3 col-6 mb-2">
                  <div className="card custom-card-pointer h-100" onClick={()=> openLink(product.link) }>
                      <img src={product.image_link} className="img-fluid" alt="product_image" />
                    <div className="card-body">
                      <div className="d-flex flex-column h-100">
                        <div className="mt-auto">
                          <h5 className="card-title">{product?.title}</h5>
                          <div className="card-text">
                            <div><b className="text-success">{getPrice(product)}</b></div>
                            <div className="text-muted">{getBrand(product)}</div>
                            <a target="_blank" rel="noreferrer" href={product?.URL || product?.produkturl?.[0]} className="text-muted">{getShopOrigin(product)}</a>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  </div>
              ))}
              </div>
              {isLoading ? 
                <div className="d-flex justify-content-center mt-3">
                <div className="spinner-border text-primary" role="status" style={{height:'4rem', width:'4rem'}}>
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
                : products.length ? '' : 'No products found...' }  
            </div>
            <div className="bg-light bottom-0 footer position-fixed px-3 w-100 d-flex justify-content-between">
              <div><div><b>Nordpar ApS</b></div> C.F. Møllers Allé 2C, 2300 Copenhagen Denmark</div>
              <div>
              <a className="d-flex justify-content-end" href="/impressum">Impressum</a>
              <div>+45 49 40 41 49 | info@nordpar.dk</div>
              </div>
            </div>
          </>
      )
    }
  


export default Home