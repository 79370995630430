import React from 'react';
 
function isDev(){ 
  return '_self' in React.createElement('div');
}

export async function fetchProducts(country:string, query: { search:string, maxPrice?:number, storeId?:string|null }, from?:number) {
  const origin = isDev() ? `http://localhost:5002` : `https://api.shopping.nordpar.com`
  
  from = from || 0
  // const count = 52
  const count = 24
  const queryEncoded = encodeURIComponent(query.search) || '%20'
  const storeId = query.storeId || '_'

  const url =  `${origin}/search-products/${country}/${queryEncoded}/${from}/${count}/${query.maxPrice}/${storeId}`
  
  const res = await fetch(url)

  return await res.json()
}

let handle:NodeJS.Timeout | undefined

export function getUpdateProducts(opts: {
  setProducts: React.Dispatch<React.SetStateAction<any[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}) {
  return (country:string, query: { search:string, maxPrice?:number, storeId?:string|null }) => {
    if(handle != null) clearTimeout(handle)

    handle = setTimeout(async ()=>{
      const products = await fetchProducts(country, query)
      opts.setProducts(products)
      opts.setLoading(false)
    }, 6_00)
  }
}