import React, {useEffect, useState, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'

import { fetchProducts, getUpdateProducts } from './products'
import { Dropdown, Form } from 'react-bootstrap'

let isInit = true
let currSearch = ''
let currMaxPrice: number | undefined
let currStoreId: string | undefined | null
let from = 0

function isDev(){ 
  return '_self' in React.createElement('div');
}

const stores: {[country:string] : string[]} = {}
let isRunningStores = false

async function getStores(country:string): Promise<string[]> {
  if(isRunningStores) {
    await new Promise(r=>setTimeout(r,100))
    return getStores(country)
  }
  if(stores[country]) return stores[country]
  
  isRunningStores = true

  const url = isDev() ? `http://localhost:5002/get-stores/${country}` : `https://api.shopping.nordpar.com/get-stores/${country}`
  
  try {
    const res = await fetch(url)
    if(res.status >= 400) stores[country] = []
    else {
      const json = await res.json() as string[]
      stores[country] = json
    }
  } catch {
    stores[country] = []
  }
  isRunningStores = false
  return stores[country]
}

export default function Navbar(opts: {
  products:any[],
  stores:any[],
  isLoading:boolean,
  setProducts: React.Dispatch<React.SetStateAction<any[]>>,
  setStores: React.Dispatch<React.SetStateAction<any[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  country:string
  setCountry: React.Dispatch<React.SetStateAction<string>>
  countries: { country: string; flag: string, name:string }[]
}) {
  const def: { search:string, maxPrice?:number, storeId?:string|null } = { search:'', maxPrice: undefined }
  const [query, setQuery] = useState(def)
  
  const history = useHistory()
  
  const updateProducts = getUpdateProducts(opts)
  
  useEffect(() => {
    getStores(opts.country).then(stores => opts.setStores(stores))
    if(isInit) {
      isInit = false
      const search = new URLSearchParams(history.location.search).get('search') || ''
      const maxPrice0 = new URLSearchParams(history.location.search).get('maxPrice')

      const maxPrice = maxPrice0 ? Number(maxPrice0) : undefined
      currSearch = search
      currMaxPrice = maxPrice

      const curr = { search: currSearch, maxPrice: currMaxPrice }
      setQuery(curr)
      updateProducts(opts.country, curr)
    } 
    const params = new URLSearchParams()
    
    if (query.search) params.append("search", query.search)
    else params.delete('search')

    if(query.maxPrice) params.append('maxPrice', `${query.maxPrice}`)
    else params.delete('maxPrice')

    if (query.storeId) params.append("storeId", query.storeId)
    else params.delete('storeId')

    
    history.push({search: params.toString()})
  }, [query, history, updateProducts, opts])

  useBottomScrollListener(async ()=> {
    if(opts.isLoading) return
    opts.setLoading(true)
    
    from = from + 50
    const products = await fetchProducts(opts.country, {search: currSearch, maxPrice: currMaxPrice, storeId:currStoreId }, from)
    opts.setProducts([...opts.products, ...products])
    opts.setLoading(false)
  })

  const onChange = (inputType:'search'|'maxPrice'|'storeId', e: React.ChangeEvent<HTMLInputElement> | string) => {
    from = 0
    opts.setProducts([])
    opts.setLoading(true)
    const val = typeof e === 'string' ? e : e.currentTarget.value

    if(inputType === 'search') currSearch = val
    if(inputType === 'storeId') currStoreId = val
    if(inputType === 'maxPrice') currMaxPrice = val ? Number(val) : undefined

    updateProducts(opts.country, {search:currSearch, maxPrice: currMaxPrice, storeId: currStoreId })
    setQuery({search:currSearch, maxPrice: currMaxPrice, storeId: currStoreId })
  }

  const CustomToggle = React.forwardRef((o: any, ref) => (
    <button 
      ref={ref as any} 
      className="bg-light border-0" 
      onClick={(e)=>{
        e.preventDefault()
        o.onClick(e)
      }}
    >
      {o.children}
    </button>

  ))

  const selectedCountry = opts.countries.find(f=>f.country === opts.country)

  const dropdownRef = useRef(null as any)


  return (
    <nav className="navbar navbar-expand navbar-light bg-light">
      <div className="container-fluid">
        <span className="navbar-brand mb-0 h1">Nordpar</span>

        <div className="form-group has-search me-auto ps-2 col-md-6 pe-2">
          <div className="fa fa-search form-control-feedback">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
          </div>
            <input autoFocus={true} type="text" value={query.search} onChange={(e)=>onChange('search',e)} className="form-control" placeholder="What are you looking for?" />
          </div>
            <Dropdown ref={dropdownRef}>
                  <Dropdown.Toggle as={CustomToggle}>
                    {selectedCountry ? 
                      <>
                      <svg width="30" height="30" dangerouslySetInnerHTML={{__html:selectedCountry.flag}} />
                      <svg width="30" height="30" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M7 10l5 5 5-5"/></g></svg>
                      </> 
                    : <></>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div style={{width:"40vw"}}>
                      <h5 className='ms-2'>Choose country</h5>
                      <hr />
                      <div className="row list-group list-group-horizontal mx-0">
                        {opts.countries.map((country, i) => (
                          <div key={i} className="col-md-3 col-12 col mb-4 text-center">
                            <Link 
                            className={`list-group-item list-group-item-action list-group-item-light ${country?.country === opts?.country ? 'active' : ''}`}
                            to={'/' + country.country} 
                            onClick={()=>{
                              from = 0
                              opts.setProducts([])
                              opts.setLoading(true)
                              opts.setCountry(country.country)
                              updateProducts(country.country, {search:currSearch, maxPrice: currMaxPrice })
                              dropdownRef.current?.click?.()
                            }}>
                              <div style={{width: "30px", height:'60px'}} className='d-flex mx-auto'>
                                <svg
                                  dangerouslySetInnerHTML={{__html: country.flag}}
                                  width="100%"
                                  height="100%"
                                  />
                              </div>
                                  {country.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dropdown.Menu>
            </Dropdown>
        <div>
        <div>
        </div>
          <Dropdown>
          <Dropdown.Toggle as={CustomToggle} >
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-filter-right" viewBox="0 0 16 16">
              <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            <div className="my-2 mx-2" style={ { width:'300px' } }>
              <Form.Group>
                <input placeholder='Max price' value={query.maxPrice} onChange={(e)=>onChange('maxPrice',e)} className="form-control" type="number" />
              </Form.Group>
              <form>
              <div className="mt-2">
                <div><b>Stores</b></div>
                <div className="overflow-auto" style={{maxHeight:'300px'}} >

                    <Form.Check type='radio' id='radio_0' name="store" label='All' onChange={()=>onChange('storeId', '')} />
                    <hr />
                    {opts.stores.map((s, i) => (
                      <Form.Check key={i} type="radio" id={`radio_${i+1}`} name="store" label={s.store} onChange={()=>onChange('storeId', s.id)} />
                    ))}

                </div>
              </div>
              </form>
            </div>
          </Dropdown.Menu>
          </Dropdown>
        </div>

      </div>
    </nav>
  )
}